h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
    color: #DC006B;
    margin-top: 10px;
}

label,
.text-soliss {
    color: #DC006B;
}

fieldset>legend {
    color: #DC006B;
}

.required:after {
    content: " *";
    font-weight: bold;
    color: red;
}

#logo {
    left: 1em;
    padding-top: 15px;
    /*padding: 1.5em;*/
    text-align: left;
    top: 1em;
}

.text-bold {
    font-weight: bold;
}

textarea .form-control {
    width: 100%;
}

.error {
    color: red !important;
}

/*AJUSTES DE ESTILO DE CALCULO*/
/**/
h2,
.h2 {
    font-size: 18px;
}

h3,
.h3 {
    font-size: 16px;
}

label {
    font-weight: 100;
}

/*\ESTILOS CALCULO*/
/*VALIDATION ERROR*/
.input-validation-error {
    border-color: rgba(220, 0, 107, 0.92);
    border-width: 2px;
    background-color: rgba(224, 168, 148, 0.22);
}

/****PERSONALIZACIÓN DE BOOTSTRAP******/
.navbar-default {
    background-image: -webkit-gradient(linear, left bottom, left top, color-stop(0, #DC006A), color-stop(1, #E7529A));
    background-image: -o-linear-gradient(top, #DC006A 0%, #E7529A 100%);
    background-image: -moz-linear-gradient(top, #DC006A 0%, #E7529A 100%);
    background-image: -webkit-linear-gradient(top, #DC006A 0%, #E7529A 100%);
    background-image: -ms-linear-gradient(top, #DC006A 0%, #E7529A 100%);
    background-image: linear-gradient(to top, #DC006A 0%, #E7529A 100%);
    border-color: transparent;
    font-size: 16px;
    margin-bottom: 0px;
}

.navbar-default .navbar-nav>li>a {
    color: white;
}

.navbar-default .navbar-nav>li>a:hover,
.navbar-default .navbar-nav>li>a:focus {
    color: white;
    text-decoration: underline;
}

.navbar-default .navbar-nav>.open>a,
.navbar-default .navbar-nav>.open>a:hover,
.navbar-default .navbar-nav>.open>a:focus {
    background-color: transparent;
    color: white;
}

.navbar-default .navbar-nav>.dropdown>a .caret,
.navbar-default .navbar-nav>.dropdown>a:hover .caret,
.navbar-default .navbar-nav>.dropdown>a:focus .caret {
    border-top-color: white;
    border-bottom-color: white;
}

.dropdown-menu {
    border: 1px solid #DC006B;
}

.navbar-default .navbar-toggle {
    border-color: white;
    color: white;
}

.navbar-default .navbar-toggle:hover,
.navbar-default .navbar-toggle:active,
.navbar-default .navbar-toggle:focus {
    background-color: transparent;
    border-color: white;
    outline-color: transparent;
}

.navbar-brand {
    padding: 0;
}

.pagination>li>a,
.pagination>li>span {
    color: #DC006B;
}

.pagination>li>a:hover,
.pagination>li>span:hover,
.pagination>li>a:focus,
.pagination>li>span:focus {
    color: darkmagenta;
}

.pagination>.active>a,
.pagination>.active>a:focus,
.pagination>.active>a:hover,
.pagination>.active>span,
.pagination>.active>span:focus,
.pagination>.active>span:hover {
    background-color: #DC006B;
    border-color: #DC006B;
}

.row {
    margin-left: 0;
    margin-right: 0;
}

.nav-tabs>li>a,
.nav-tabs>li>a:focus,
.nav-tabs>li>a:hover {
    border: 1px solid rgba(193, 185, 185, 0.81);
    color: #e22d85;
}

.nav-tabs>li.active>a,
.nav-tabs>li.active>a:focus,
.nav-tabs>li.active>a:hover {
    color: #fff;
    background-color: #e22d85;
}

.list-group-item {
    border: none;
    cursor: pointer;
}

.list-group-item:nth-child(odd) {
    background-color: #F5F5F5;
}

.list-group-item:nth-child(even) {
    background-color: white;
}

.list-group-item:hover {

    background-color: rgba(220, 0, 107, 0.63) !important;
    color: white !important;

}


.list-group-item.active,
.list-group-item.active:focus,
.list-group-item.active:hover {
    background-color: #dc006b !important;
}

.list-group-item {
    text-transform: uppercase;
}

.list-group-item a {
    color: #1889e6;
}

.list-group-item a:hover {
    text-decoration: underline;
}

.list-group-item a:visited {
    color: black;
}

.btn-pdf {
    position: absolute;
    right: 0;
    top: 0;
}

.btn-soliss,
.btn-soliss:focus,
.btn-soliss:active {
    color: #fff !important;
    background-image: -ms-linear-gradient(bottom, #E50766 0%, #FC75AE 100%);
    background-image: -moz-linear-gradient(bottom, #E50766 0%, #FC75AE 100%);
    background-image: -o-linear-gradient(bottom, #E50766 0%, #FC75AE 100%);
    background-image: -webkit-gradient(linear, left bottom, left top, color-stop(0, #E50766), color-stop(1, #FC75AE));
    background-image: -webkit-linear-gradient(bottom, #E50766 0%, #FC75AE 100%);
    background-image: linear-gradient(to top, #E50766 0%, #FC75AE 100%);
    border-color: transparent !important;
}

.btn-soliss:hover,
.open>.dropdown-toggle.btn-soliss {
    color: #fff !important;
    background-image: -ms-linear-gradient(bottom, #009BCC 0%, #3FCEFB 100%);
    background-image: -moz-linear-gradient(bottom, #009BCC 0%, #3FCEFB 100%);
    background-image: -o-linear-gradient(bottom, #009BCC 0%, #3FCEFB 100%);
    background-image: -webkit-gradient(linear, left bottom, left top, color-stop(0, #009BCC), color-stop(1, #3FCEFB));
    background-image: -webkit-linear-gradient(bottom, #009BCC 0%, #3FCEFB 100%);
    background-image: linear-gradient(to top, #009BCC 0%, #3FCEFB 100%);
    border-color: transparent;
    outline-color: transparent;
}

.btn-soliss.disabled,
.btn-soliss[disabled],
fieldset[disabled] .btn-soliss,
.btn-soliss.disabled:hover,
.btn-soliss[disabled]:hover,
fieldset[disabled] .btn-soliss:hover,
.btn-soliss.disabled:focus,
.btn-soliss[disabled]:focus,
fieldset[disabled] .btn-soliss:focus,
.btn-soliss.disabled.focus,
.btn-soliss[disabled].focus,
fieldset[disabled] .btn-soliss.focus,
.btn-soliss.disabled:active,
.btn-soliss[disabled]:active,
fieldset[disabled] .btn-soliss:active,
.btn-soliss.disabled.active,
.btn-soliss[disabled].active,
fieldset[disabled] .btn-soliss.active {
    background: gray !important;
    border-color: transparent !important;
}

.btn-soliss .badge {
    color: #1889E6;
    background-color: #fff;
}

.control-label-left {
    margin-bottom: 0;
    padding-top: 7px;
    text-align: left;
}

.glyphicon-soliss {
    color: #e01878;
    font-size: 24px !important;
    padding: 10px;
    text-transform: none !important;
}

.glyphicon-soliss:hover {
    background-color: rgba(220, 0, 107, .63) !important;
    color: white;
}

.glyphicon-soliss.lupa {
    font-size: 12px !important;
}

.tooltip-inner {
    background-color: #DC006B;
}

.tooltip.top .tooltip-arrow {
    border-top-color: #DC006B;
}

.jumbotron h1 {
    color: #DC006B;
}

fieldset.scheduler-border {
    border: 1px solid #ccc !important;
    border-radius: 4px;
    /*display: inline-block; Evito que ocupe todo el ancho*/
    padding: 0 1.4em 1.4em 1.4em !important;
    margin: 0 1.5em 0 1.5em !important;
    -webkit-box-shadow: 0px 0px 0px 0px #000;
    box-shadow: 0px 0px 0px 0px #000;
}

legend.scheduler-border {
    font-size: 1.2em !important;
    font-weight: bold !important;
    color: #dc006b;
    text-align: left !important;
    width: inherit;
    /* Or auto */
    padding: 0 10px;
    /* To give a bit of padding on the left and right */
    border-bottom: none;
}

div.scrollable {
    overflow-y: auto;
    overflow-x: hidden;
    height: 630px;
}


.footer {
    clear: both;
    height: 70px !important;
    width: 100%;
    margin: 0 auto;
    position: fixed !important;
    bottom: 0 !important;
}


@media (max-width: 767px) {
    .navbar-default .navbar-nav .open .dropdown-menu>li>a {
        color: white;
    }

    .navbar-default .navbar-nav .open .dropdown-menu>li>a:hover,
    .navbar-default .navbar-nav .open .dropdown-menu>li>a:focus {
        color: white;
        text-decoration: underline;
    }
}

@media(max-width:1300px) {
    #menu {
        font-size: 13px;
    }
}

/******************************************************************/

/****PERSONALIZACIÓN DE BOOTSTRAP-DATEPICHER******/
.datepicker table tr td.today,
.datepicker table tr td.today:hover,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today.disabled:hover {
    background-color: #FC75AE;
}

.datepicker table tr td.today:hover,
.datepicker table tr td.today:hover:hover,
.datepicker table tr td.today.disabled:hover,
.datepicker table tr td.today.disabled:hover:hover,
.datepicker table tr td.today:focus,
.datepicker table tr td.today:hover:focus,
.datepicker table tr td.today.disabled:focus,
.datepicker table tr td.today.disabled:hover:focus,
.datepicker table tr td.today:active,
.datepicker table tr td.today:hover:active,
.datepicker table tr td.today.disabled:active,
.datepicker table tr td.today.disabled:hover:active,
.datepicker table tr td.today.active,
.datepicker table tr td.today.active:hover,
.datepicker table tr td.today.disabled.active,
.datepicker table tr td.today.disabled.active:hover,
.open .dropdown-toggle.datepicker table tr td.today,
.open .dropdown-toggle.datepicker table tr td.today:hover,
.open .dropdown-toggle.datepicker table tr td.today.disabled,
.open .dropdown-toggle.datepicker table tr td.today.disabled:hover {
    background-color: #DC006B;
}

.datepicker table tr td.active:hover,
.datepicker table tr td.active:hover:hover,
.datepicker table tr td.active.disabled:hover,
.datepicker table tr td.active.disabled:hover:hover,
.datepicker table tr td.active:focus,
.datepicker table tr td.active:hover:focus,
.datepicker table tr td.active.disabled:focus,
.datepicker table tr td.active.disabled:hover:focus,
.datepicker table tr td.active:active,
.datepicker table tr td.active:hover:active,
.datepicker table tr td.active.disabled:active,
.datepicker table tr td.active.disabled:hover:active,
.datepicker table tr td.active.active,
.datepicker table tr td.active.active:hover,
.datepicker table tr td.active.disabled.active,
.datepicker table tr td.active.disabled.active:hover,
.open .dropdown-toggle.datepicker table tr td.active,
.open .dropdown-toggle.datepicker table tr td.active:hover,
.open .dropdown-toggle.datepicker table tr td.active.disabled,
.open .dropdown-toggle.datepicker table tr td.active.disabled:hover {
    background-color: #1889E6;
}

/**************************************************/
/*#E50766*/

/*MODAL DIALOG*/
.modal-header {
    background-color: #dc006b;
}

.modal-title {
    color: white;
}

#polizas_wrapper {
    margin-top: 40px;
}


/**************************************************/
/*  ESTILOS NUEVA FUNCIONALIDAD */
/**************************************************/

table tbody tr {
    cursor: pointer;
}

.srch {
    cursor: pointer;
}

.addon-info {
    background-color: white;
}

.badge-info {
    background-color: rgb(46, 132, 193) !important;
}

.partePendiente {
    font-weight: bold;
    background-color: darkgrey !important;
    cursor: default;
}

.solicitudPendiente {
    font-weight: bold;
    background-color: darkgrey !important;
}

.listItem {
    cursor: default !important;
}

.appInfoMsj {
    position: fixed !important;
    bottom: 0 !important;
    width: 100% !important;
    z-index: 99999 !important;
    left: 0 !important;
    margin: 0 !important;
    text-align: center !important;
    font-size: 1.3em !important;
}

table>thead>tr>th.filter input {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
}

.sorting {
    text-align: center !important;
}

.overlay {
    height: 100%;
    width: 100%;
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;   
    /*background-color: rgb(0,0,0); /* Black fallback color */
    /*background-color: rgba(0,0,0, 0.6); /* Black w/opacity */
 }