.custom-tooltip {
  background-color: aliceblue;
  padding: 4px;
  border: 2px solid black;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}

.bs-docs-footer {
  border-top: 1px solid #e5e5e5;
  color: #777;
  margin-top: 100px;
  padding-bottom: 40px;
  padding-top: 40px;
  text-align: center;
}



/* Container holding the image and the text */
.cont {
  position: relative;
  padding-top: 2%;
}


.align-self-end, .align-self-center > h5,h6{
font-weight: bold !important;
}

.podImg {
  position: relative;
  width: 100%;
  left: 0%;
}
 
.resCont{
  width: 500px;
  margin: 0 auto;
}


/* Loading */
.loadingCont{
  padding: 2%;
  margin: 0 auto;
  background-color: darkgrey;
}


.cardMode :hover{
  background-color: lightgray;
  border-radius: 0.25rem;
}

.cardMode :hover >h5{
  font-weight: bold;
}
